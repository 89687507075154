import Cleave from 'cleave.js'
import { Controller } from '@hotwired/stimulus'
import 'cleave.js/dist/addons/cleave-phone.us'

export default class extends Controller {
  static values = {
    options: { type: Object, default: {} }
  }

  connect () {
    this.cleaveInstance = new Cleave(this.element, {
      numericOnly: true,
      blocks: [0, 3, 0, 3, 4],
      delimiters: ["(", ")", "-"]
    })
  }

  disconnect () {
    this.cleaveInstance.destroy()
  }
}
