import { Controller } from "@hotwired/stimulus"
import { debounce } from "../helpers/debounce_helpers"

// Connects to data-controller="autosubmit"
export default class extends Controller {
  static targets = ['form']

  initialize() {
    this.debouncedSubmit = debounce(this.debouncedSubmit.bind(this), 300)
  }

  submit(e) {
    this.formTarget.requestSubmit()
  }

  debouncedSubmit() {
    this.submit()
  }
}
