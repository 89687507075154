// Core libraries
import { Application } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
import { Turbo } from "@hotwired/turbo-rails"

// Make accessible for Electron and Mobile adapters
window.Rails = Rails
window.Turbo = Turbo

// App libraries
import "@rails/actiontext"
import "trix"
import "./libraries/visit"

// lazy loader for images
import lazySizes from 'lazysizes'

import AnnouncementBannerController from "./controllers/announcement_banner_controller"
import AutosubmitController from "./controllers/autosubmit_controller"
import DatefieldController from "./controllers/datefield_controller"
import DropdownController from "./controllers/dropdown_controller"
import DismissController from "./controllers/dismiss_controller"
import FormatPhoneNumberController from "./controllers/format_phone_number_controller"
import GeolocationController from "./controllers/geolocation_controller"
import ModalController from "./controllers/modal_controller"
import ModalCloserController from "./controllers/modal_closer_controller"
import NestedFormController from "./controllers/nested_form_controller"
import PlacesController from "./controllers/places_controller"
import PopoverController from "./controllers/popover_controller"
import ResetFormController from "./controllers/reset_form_controller"
import SelectController from "./controllers/select_controller"
import SlideshowController from "./controllers/slideshow_controller"
import StepsController from "./controllers/steps_controller"
import StripeController from "./controllers/stripe_controller"
import StripeAchDebitController from "./controllers/stripe_ach_debit_controller"
import SignatureController from "./controllers/signature_controller"
import StoreToggleController from "./controllers/store_toggle_controller"
import SubmitFormController from "./controllers/submit_form_controller"
import TableLinkController from "./controllers/table_link_controller"
import TabsController from "./controllers/tabs_controller"
import ToggleController from "./controllers/toggle_controller"
import UnitFiltersController from "./controllers/unit_filters_controller"

const application = Application.start()
application.register("announcement-banner", AnnouncementBannerController)
application.register("autosubmit", AutosubmitController)
application.register("datefield", DatefieldController)
application.register("dismiss", DismissController)
application.register("dropdown", DropdownController)
application.register("format-phone-number", FormatPhoneNumberController)
application.register("geolocation", GeolocationController)
application.register("modal", ModalController)
application.register("modal-closer", ModalCloserController)
application.register("nested-form", NestedFormController)
application.register("places", PlacesController)
application.register("popover", PopoverController)
application.register("reset-form", ResetFormController)
application.register("select", SelectController)
application.register("signature", SignatureController)
application.register("slideshow", SlideshowController)
application.register("steps", StepsController)
application.register("stripe", StripeController)
application.register("stripe-ach-debit", StripeAchDebitController)
application.register("store-toggle", StoreToggleController)
application.register("submit-form", SubmitFormController)
application.register("table-link", TableLinkController)
application.register("tabs", TabsController)
application.register("toggle", ToggleController)
application.register("unit-filters", UnitFiltersController)

window.dispatchMapsEvent = function (...args) {
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}
